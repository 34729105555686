let currentActiveBannerId = 0;
$(`#banner-${currentActiveBannerId}`).show();

function currentBannerSize() {
  window.bannerSize = window.bannerSize || document.querySelectorAll('.banner-slider-element img').length;

  return window.bannerSize;
}

// @ts-ignore
if (currentBannerSize() > 1) {
  //// PRELOAD DELLE IMMAGINI ////
  $('.banner-slider-element img').each(function (index, obj) {
    new Image().src = obj.src;
  });

  setInterval(function () {
    $(`#banner-${currentActiveBannerId}`).hide();

    // @ts-ignore
    if (currentActiveBannerId + 1 >= currentBannerSize()) {
      currentActiveBannerId = 0;
    } else {
      currentActiveBannerId = currentActiveBannerId + 1;
    }

    let $elem = $(`#banner-${currentActiveBannerId}`);

    $elem.show();
    $elem.find('a, img').show();
  }, 5000);
}
